export const matchUrlPath = (
  url: string,
  pattern: string,
  end = false
): boolean => {
  const urlTokens = url.split('/'),
    patternTokens = pattern.split('/')
  let i = 0,
    j = 0
  while (i < urlTokens.length && j < patternTokens.length) {
    // '/:id' should match with '/uhsn2563ndsd'
    if (patternTokens[j][0] !== ':' && patternTokens[j] !== urlTokens[i]) {
      return false
    }
    i++
    j++
  }
  return j === patternTokens.length && (!end || i === urlTokens.length)
}
