export const sideMenuMap = {
  '/jobs': 'jobs',
  '/mentor': 'mentor',
  '/home': 'dashboard',
  '/company': 'company',
  '/projects': 'projects',
  '/calendar': 'calendar',
  '/bookings': 'bookings',
  '/settings': 'dashboard',
  '/community': 'community',
  '/resources': 'resources',
  '/cohort': 'learning-hub',
  '/webinar': 'learning-hub',
  '/payment': 'learning-hub',
  '/talent-hub': 'learning-hub',
  '/vfs/reports': 'vfs/reports',
  '/masterclass': 'learning-hub',
  '/company/followers': 'company',
  '/learning-hub': 'learning-hub',
  '/learning-path': 'learning-hub',
  '/apprentice-dashboard': 'for-you',
  '/career-advisor': 'career-advisor',
  '/corporate/jobs': 'corporate/jobs',
  '/learning-hub/mentors': 'learning-hub',
  '/corporate/jobs/create-job': 'create-job',
  '/corporate/jobs/[id]': 'corporate/jobs/:id',
  '/cohort-management': '/cohort-management/event',
  '/corporate/talent-pool': 'corporate/talent-pool',
  '/corporate-v2/campaign': 'corporate-v2/campaign',
  '/corporate/integrations': 'corporate/integrations',
  '/corporate/talent-pool/[id]': 'corporate/talent-pool/:id',
  '/corporate-v2/campaign/[id]': 'corporate-v2/campaign/:id',
  '/corporate-v2/configuration': 'corporate-v2/configuration',
  '/corporate/jobs/edit-jobs/[id]': 'corporate/jobs/edit-jobs/:id',
  '/vfs/job-description-generator': 'vfs/job-description-generator',
  '/corporate-v2/campaign/[id]/candidates':
    'corporate-v2/campaign/:id/candidates',
  '/corporate-v2/configuration/campaign': 'corporate-v2/configuration/campaign',
  '/corporate-v2/configuration/campaign/[id]':
    'corporate-v2/configuration/campaign/:id',
  '/corporate-v2/workspace': 'corporate-v2/workspace',
  '/corporate-v2/workspace/[workspaceId]/source':
    'corporate-v2/workspace/:workspaceId/source',
  '/corporate-v2/workspace/[workspaceId]/source/[sourceId]':
    'corporate-v2/workspace/:workspaceId/source/:sourceId'
}
