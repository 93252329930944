const InferredJobIcon = props => (
  <svg
    fill="none"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.61565 14.457L8.08696 16.1818L8.55826 14.457C8.68007 14.0114 8.90846 13.6057 9.22188 13.278C9.53529 12.9504 9.9234 12.7116 10.3496 12.5842L12 12.0909L10.3501 11.5982C9.92398 11.4708 9.53587 11.2321 9.22246 10.9044C8.90904 10.5767 8.68065 10.171 8.55884 9.72545L8.08696 8L7.61565 9.72485C7.49384 10.1704 7.26545 10.5761 6.95203 10.9038C6.63862 11.2315 6.25052 11.4702 5.82435 11.5976L4.17391 12.0909L5.82377 12.5836C6.24994 12.711 6.63804 12.9498 6.95145 13.2774C7.26487 13.6051 7.49326 14.0108 7.61507 14.4564L7.61565 14.457ZM3.51073 17.283L3.73913 18L3.96754 17.283C4.03157 17.0822 4.13945 16.8997 4.28263 16.75C4.42581 16.6003 4.60037 16.4875 4.79246 16.4206L5.47826 16.1818L4.79246 15.943C4.60037 15.8761 4.42581 15.7633 4.28263 15.6136C4.13945 15.4639 4.03157 15.2814 3.96754 15.0806L3.73913 14.3636L3.51073 15.0806C3.44669 15.2814 3.33881 15.4639 3.19563 15.6136C3.05245 15.7633 2.8779 15.8761 2.6858 15.943L2 16.1818L2.6858 16.4206C2.8779 16.4875 3.05245 16.6003 3.19563 16.75C3.33881 16.8997 3.44669 17.0822 3.51073 17.283Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 19H13C14.1046 19 15 18.1046 15 17V7.41421C15 7.149 14.8946 6.89464 14.7071 6.70711L9.29289 1.29289C9.10536 1.10536 8.851 1 8.58579 1H3C1.89543 1 1 1.89543 1 3V14"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
)

export default InferredJobIcon
