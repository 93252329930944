import { ICohortPricing, OfferingEnum, TaskDTO } from '..'

export enum CohortType {
  CUSTOM = 'custom',
  QUREOS = 'qureos'
}

export enum CohortStatus {
  // TODO: Remove extra statuses
  DRAFT = 'draft',
  // v not used?
  PUBLISHED = 'published',
  IN_REVIEW = 'review',
  PENDING = 'pending',
  // v not used?
  ACTIVE = 'active',
  APPROVED = 'approved',
  EVENT_CANCELLED = 'event_cancelled',
  // masterclass
  CANCELLED = 'cancelled'
}

interface CancellationPolicy {
  units: number
  measure: string
}

export enum CohortNewStatus {
  Pending = 'pending',
  Active = 'active'
}

export interface CohortPricing {
  amount?: number
  discount?: number
  cancellationPolicy?: CancellationPolicy
}

export enum MentorshipMeetingClient {
  ZOOM = 'zoom',
  GOOGLE = 'google'
}

export interface CohortMentorshipSession {
  _id?: string
  startDateTime: Date
  endDateTime: Date
  meetingUrl?: string
  meetingChannel?: MentorshipMeetingClient
  recordingUrl?: string
}
export interface SessionDuration {
  units: number
  measure: string
}

export interface CategorySkillDto {
  _id: string
  label: string
  value?: string
}

export interface CohortCompanyDto {
  _id: string
  name: string
  logo: string
  domains: string[]
  location: string[]
  brief: string
  website: string
  employees: string
}

export interface CohortDTO {
  _id?: string
  cohortType: CohortType
  totalSeats?: number
  status?: CohortStatus
  skill: CategorySkillDto
  title?: string
  timezone?: string
  description?: string
  photo?: string
  startDateTime?: Date
  endDateTime?: Date
  enrollByDateTime?: Date
  duration?: SessionDuration
  language?: string
  pricing: CohortPricing
  offering?: OfferingEnum
  mentorshipSession?: CohortMentorshipSession[]
  company: CohortCompanyDto | any
  category: CategorySkillDto
  tasks?: TaskDTO[]
  createdBy?: { _id: string; name: string }
  urlSlug?: string
  learningPathModule?: any
  preModuleAssessmentUrl?: string
}

export interface ICohort {
  _id?: string
  cohortType: CohortType
  createdDateTime?: Date
  status?: CohortStatus
  updatedDateTime?: Date
}
export interface cohortApprentices {
  applicationDateTime: string
  apprentice: {
    countryCode: string
    countryFlag: string
    name: string
    firstName: string
    lastName: string
    photo: string
    _id: string
  }
  cohort: {
    _id: string
    title: string
  }
  createdDateTime: string
  feedback: {
    comments: string
    rating: number
    createdDateTime: string
  }
  mentor: { _id: string; name: string }
  mode: string
  stage: string
  updatedDateTime: string
  _id: string
  finalAverageRating: number
}
export interface scheduleType {
  cohortType: string
  status: string
  _id: string
  startDateTime: string | Date
  endDateTime: string | Date
  icsUrl: string
  tasks: {
    name: string
    duration: { period: number; type: string }
    resources: { name: string; url: string }[]
    description: string
    endDateTime: string
    startDateTime: string
    templateId: string
    _id: string
  }[]
  mentorshipSession: CohortMentorshipSession[]
  timezone: string
}

export interface ICohortType extends Omit<Required<CohortDTO>, 'pricing'> {
  pricing: ICohortPricing
}
