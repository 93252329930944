import { CohortStatus, UserRole, WebinarStatus } from '@qureos/types'

export const DEFAULT_USER_SIGNUP_CODE = 'default'

export const statusToTextMappingWebinar: Record<string, string> = {
  [WebinarStatus.APPROVED]: 'APPROVED',
  [WebinarStatus.DRAFT]: 'DRAFT',
  [WebinarStatus.EVENT_CANCELLED]: 'CANCELLED',
  [WebinarStatus.IN_REVIEW]: 'UNDER REVIEW',
  [WebinarStatus.PENDING]: 'PENDING',
  [WebinarStatus.PUBLISHED]: 'PUBLISHED'
}

export const statusToTextMappingCourse: Record<string, string> = {
  [CohortStatus.ACTIVE]: 'ACTIVE',
  [CohortStatus.APPROVED]: 'APPROVED',
  [CohortStatus.DRAFT]: 'DRAFT',
  [CohortStatus.EVENT_CANCELLED]: 'CANCELLED',
  [CohortStatus.IN_REVIEW]: 'UNDER REVIEW',
  [CohortStatus.PENDING]: 'PENDING',
  [CohortStatus.PUBLISHED]: 'PUBLISHED'
}

/* TODO: do the same thing for mrntor and corp */
export const roleToDashboardUrl: Partial<Record<UserRole, string>> = {
  [UserRole.STUDENT]: '/jobs'
}
